// App.js
import React, { useState, useEffect } from "react";
// Import the react-share module
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  // Import the TelegramShareButton and TelegramIcon components
  TelegramShareButton,
  TelegramIcon,
  // Import the TwitterShareButton and TwitterIcon components
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

// Import the Google icon from react-icons
import { FaGoogle } from "react-icons/fa";

// Import the local image file
import background1 from "./pictures/header_background_pic.png";

function App() {
  // State variable for jokes
  const [jokes, setJokes] = useState([]);
  // Generate a random page number between 1 and 10
  const randomPage = Math.floor(Math.random() * 500) + 1;
  const [page, setPage] = useState(randomPage);
  const [hasMore, setHasMore] = useState(true);

  // State variable for joke ids
  const [jokeIds, setJokeIds] = useState([]);

  // State variable for retries
  const [retries, setRetries] = useState(0);

  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  // Fetch jokes from icanhazdadjoke API
  const fetchJokes = async (amount) => {
    // Add the amount parameter to the fetch function
    // Change the endpoint to use the icanhazdadjoke API
    // Add the page parameter to get jokes from different pages
    const response = await fetch(
      `https://icanhazdadjoke.com/search?limit=${amount}&page=${page}`, {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    const data = await response.json();
    // Check if the response has jokes
    if (data.results.length > 0) {
      // Update the jokes state with the new jokes
      setJokes((prev) => {
        // Filter out any jokes that have the same id as the ones in the jokeIds state
        const newJokes = data.results.filter((joke) => !jokeIds.includes(joke.id));
        // Update the jokeIds state with the ids of the new jokes
        setJokeIds((prev) => [...prev, ...newJokes.map((joke) => joke.id)]);
        // Return the previous and new jokes
        return [...prev, ...newJokes];
      });
      // Return the new jokes array
      return data.results;
    } else {
      // Set the hasMore state to false to stop fetching more data
      setHasMore(false);
      // Return an empty array
      return [];
    }
  };

  // Fetch more data when the user clicks the button
  const fetchInitialData = () => {
    setCounter1((prevCounter1) => prevCounter1 + 1);
    const randomPage2 = Math.floor(Math.random() * 500) + 1;
    // Increment the page number
    setPage(randomPage2);
    // Fetch jokes with the amount of 10
    fetchJokes(1).then((newJokes) => {
      // Check if the new jokes array is empty
      if (newJokes.length === 0) {
        // Increment the retries state
        setRetries((prev) => prev + 1);
        // Check if the retries state is equal to 5
        if (retries === 5) {
          // Set the hasMore state to false to stop fetching more data
          setHasMore(false);
          // Show a message to the user
          alert("Sorry, there are no more jokes available.");
        }
      } else {
        // Reset the retries state
        setRetries(0);
      }
    });
  };


  // Fetch more data when the user clicks the button
  const fetchMoreData = () => {
    setCounter1((prevCounter1) => prevCounter1 + 1);
    const randomPage3 = Math.floor(Math.random() * 500) + 1;
    // Increment the page number
    //setPage((prev) => prev + 1);
    setPage(randomPage3);
    // Fetch jokes with the amount of 10
    fetchJokes(1).then((newJokes) => {
      // Check if the new jokes array is empty
      if (newJokes.length === 0) {
        // Increment the retries state
        setRetries((prev) => prev + 1);
        // Check if the retries state is equal to 5
        if (retries === 5) {
          // Set the hasMore state to false to stop fetching more data
          setHasMore(false);
          // Show a message to the user
          alert("Sorry, there are no more jokes available.");
        }
      } else {
        // Reset the retries state
        setRetries(0);
      }
    });
  };

  // Fetch initial data when the component mounts
  useEffect(() => {
    // Fetch jokes with the amount of 5
    //fetchJokes(1);
    if (counter1<5){
      //fetchInitialData();
      fetchMoreData();
    }
  }, [counter1]);

  // Use only jokes for the items array
  const items = jokes;

  // Add a style object for the header section
  const headerStyle = {
    // Use the local image file for the background image
    backgroundImage: `url(${background1})`,
    backgroundSize: "400px", // change this value as you like
    backgroundPosition: "center",
    backgroundRepeat: "repeat-x", // add this line
    height: "140px",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    // Add a media query to hide the header elements when the width is less than 800px
    "@media (max-width: 800px)": {
      display: "none",
    },
  };

  // Add a style object for the content section
  const contentStyle = {
    // Use flexbox to align the content vertically
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // Add a margin-top to avoid overlapping with the header section
    marginTop: "140px",
  };

  // Create a state variable for the media query
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Create a media query object
  const mediaQuery = window.matchMedia("(max-width: 400px)");

  // Define a function to update the state variable
  const updateScreenSize = () => {
    setIsSmallScreen(mediaQuery.matches);
  };

  // Add an event listener when the component mounts
  useEffect(() => {
    mediaQuery.addEventListener("change", updateScreenSize);
    // Update the isSmallScreen state variable here
    setIsSmallScreen(mediaQuery.matches);
    // Remove the event listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", updateScreenSize);
  }, []);

  // Create a state variable for the language of the user
  const [language, setLanguage] = useState("en");

  // Define a function to detect the language of the user
  const detectLanguage = () => {
    // Use the navigator.language property to get the language of the user's browser
    const lang = navigator.language;
    // Set the language state variable to the first two characters of the lang string
    setLanguage(lang.slice(0, 2));
  };

  // Call the detectLanguage function when the component mounts
  useEffect(() => {
    detectLanguage();
  }, []);


  return (
    <div className="App">
      {/* Add the headerStyle to the header section */}
      <div style={headerStyle}>
      <h1
        id="header01"
        style={{
          textAlign: "center",
          color: "white",
          fontSize: isSmallScreen ? "27px" : "34px",
          // Add this line
          textShadow: "2px 2px 4px blue, -2px -2px 4px red",
        }}
      >
        MY BAD JOKES . COM
      </h1>

        <p
          id="header02"
          style={{
            textAlign: "center",
            color: "white",
            fontSize: isSmallScreen ? "15px" : "15px",
          }}
        >
          Happy New Year 2024! <br />
          Proceed with caution! You might laugh 😁
        </p>
      </div>

      {/* Add the contentStyle to the content section */}
      <div style={contentStyle}>
        {items.map((item, index) => {
          // Render a joke if the item has a joke property
          if (item.joke) {
            // Render the element normally
            return (
              <div className="Joke" key={index}>
                <p>{item.joke}</p>
                <div className="ShareButtons">
                  <FacebookShareButton
                    url="https://mybadjokes.com"
                    quote={item.joke}
                    hashtag="#mybadjokes"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url="https://mybadjokes.com"
                    title={item.joke}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  {/* Add a Telegram share button with the url and title props */}
                  <TelegramShareButton
                    url="https://mybadjokes.com"
                    title={item.joke}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                  {/* Add a Twitter share button with the url, title, via, and hashtags props */}
                  <TwitterShareButton
                    url="https://mybadjokes.com"
                    title={item.joke}
                    via="mybadjokes"
                    hashtags={["mybadjokes", "funny"]}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  {/* Add a Google button that opens a new window redirecting to a google search */}
                  <button
                    onClick={() =>
                      // Add "Explain joke" at the beginning before sending to google
                      window.open(`https://www.google.com/search?q=Explain joke reddit ${item.joke}`)
                    }
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      cursor: "pointer",
                      margin: "0 5px",
                    }}
                  >
                    <FaGoogle size={32} color="#4285F4" />
                  </button>
                </div>
                <hr style={{ color: "gray", height: "1px" }} />
              </div>
            );
          } else {
            // Render a two-part joke if the item has a setup and a delivery property
            if (item.setup && item.delivery) {
              // Render the element normally
              return (
                <div className="Joke" key={index}>
                  <p>{item.setup}</p>
                  <p>{item.delivery}</p>
                  <div className="ShareButtons">
                    <FacebookShareButton
                      url="https://mybadjokes.com"
                      quote={`${item.setup} ${item.delivery}`}
                      hashtag="#mybadjokes"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url="https://mybadjokes.com"
                      title={`${item.setup} ${item.delivery}`}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    {/* Add a Telegram share button with the url and title props */}
                    <TelegramShareButton
                      url="https://mybadjokes.com"
                      title={`${item.setup} ${item.delivery}`}
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    {/* Add a Twitter share button with the url, title, via, and hashtags props */}
                    <TwitterShareButton
                      url="https://mybadjokes.com"
                      title={`${item.setup} ${item.delivery}`}
                      via="mybadjokes"
                      hashtags={["mybadjokes", "funny"]}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    {/* Add a Google button that opens a new window redirecting to a google search */}
                    <button
                      onClick={() =>
                        // Add "Explain joke" at the beginning before sending to google
                        window.open(
                          `https://www.google.com/search?q=Explain joke reddit ${item.setup} ${item.delivery}`
                        )
                      }
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        cursor: "pointer",
                        margin: "0 5px",
                      }}
                    >
                      <FaGoogle size={32} color="#4285F4" />
                    </button>
                  </div>
                  <hr style={{ color: "gray", height: "1px" }} />
                </div>
              );
            }
          }
        })}
        {/* Add a button element to load more jokes */}
        <button
          onClick={fetchMoreData}
          style={{
            display: "block",
            margin: "0 auto",
            width: "150px",
            height: "50px",
            border: "none",
            borderRadius: "10%",
            backgroundColor: "#2D748F60",
            fontSize: "20px",
            fontWeight: "bold",
            color: "black",
            cursor: "pointer",
            // Add these properties
            boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
            transition: "all 0.3s ease-in-out",
            // Add this property
            marginBottom: "25px",
          }}
        >
          Load More
        </button>
      </div>
    </div>
  );
}

export default App;